import { getStoryblokApi, StoryblokComponent, useStoryblokState } from '@storyblok/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Layout from '~/components/Layout';
import Seo from '~/components/Seo';
import useStore from '~/hooks/useStore';


export default ({ story: initialStory, preview }) => {
  const story = useStoryblokState(initialStory, {}, preview);
  const setPage = useStore(({setPage}) => setPage);
  const router = useRouter();

  // get the background color of the first section and if is dark, show the light header
  const headerDark = Array.isArray(story?.content?.body) && story.content.body[0] && story.content.body[0].background === 'dark-blue';

  useEffect(() => {
    setPage(initialStory);
  }, [initialStory, router]);

  return (
    <Layout
      hideCTA={story?.content?.hideFooterCta || false}
      headerDark={headerDark}
      isLandingPage={story.content.navType === 'landing'}
      footerType={story.content.footerType}
      navType={story.content.navType}
      customHeaderButton={story.content.navButton}>
      <Seo
        title={story?.content?.seo?.title || story.name || ''}
        description={story?.content?.seo?.description || ''}
        openGraphImage={{ url: story?.content?.seoOgImage?.filename || '' }}
        robotsNoIndex={story?.content?.seoRobotsNoIndex || false}
        canonical={story?.content?.seoCanonical}
      />
      <StoryblokComponent blok={story.content} />
    </Layout>
  );
};


export const getStaticProps = async ({ params, preview = false }) => {
  try {
    const storyblokApi = getStoryblokApi();

    const sbParams = {
      version: 'published',
      resolve_relations: ['post.category', 'post.relatedPosts', 'Post.relatedPosts', 'post.author', 'blogList.posts']
    };

    if (preview) {
      // load the draft version inside of the preview mode
      sbParams.version = 'draft';
      sbParams.cv = Date.now();
    }

    const [{ data }] = await Promise.all([
      storyblokApi.get(`cdn/stories/homepage-v2`, sbParams)
    ]);

    let pricingData = [];

    return {
      props: {
        story: data ? data.story : false,
        preview,
      },
      revalidate: 60 * 60, // 1 hour
    };
  } catch (error) {
    console.log(error);
    return {
      notFound: true
    };
  }
};
